import React, { createContext, useContext, useState } from 'react';

interface HeaderContextData {
  style: HeaderStyle;
  changeHeaderStyle(style: Partial<HeaderStyle>): void;
}

interface HeaderStyle {
  colorLinks: string;
  colorLinksHover: string;
  backgroundColor: string;
  colorToggle: string;
  logoOficial: boolean;
}

const initialHeaderStyle: HeaderStyle = {
  colorLinks: 'var(--white)',
  colorLinksHover: 'var(--green-600)',
  backgroundColor: 'transparent',
  colorToggle: 'var(--black)',
  logoOficial: false,
};

const HeaderContext = createContext<HeaderContextData>({style: initialHeaderStyle} as HeaderContextData);

export const HeaderProvider: React.FC = ({ children }: any) => {
  const [style, setStyle] = useState<HeaderStyle>(initialHeaderStyle);

  function changeHeaderStyle(newStyle: Partial<HeaderStyle>) {
    setStyle((style) => Object.assign(style, newStyle));
  }

  return (
    <HeaderContext.Provider value={{ style, changeHeaderStyle }}>
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderContext;

//Hook pegar informações do header
export function useHeader() {
  const context = useContext(HeaderContext);

  return context;
}

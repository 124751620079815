exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-entenda-o-que-e-e-quando-utilizar-o-vale-pedagio-tsx": () => import("./../../../src/pages/blog/entenda-o-que-e-e-quando-utilizar-o-vale-pedagio.tsx" /* webpackChunkName: "component---src-pages-blog-entenda-o-que-e-e-quando-utilizar-o-vale-pedagio-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mei-caminhoneiro-2022-tsx": () => import("./../../../src/pages/blog/mei-caminhoneiro-2022.tsx" /* webpackChunkName: "component---src-pages-blog-mei-caminhoneiro-2022-tsx" */),
  "component---src-pages-blog-melhore-o-controle-de-combustivel-da-sua-empresa-tsx": () => import("./../../../src/pages/blog/melhore-o-controle-de-combustivel-da-sua-empresa.tsx" /* webpackChunkName: "component---src-pages-blog-melhore-o-controle-de-combustivel-da-sua-empresa-tsx" */),
  "component---src-pages-blog-o-que-e-cte-de-subcontratacao-tsx": () => import("./../../../src/pages/blog/o-que-e-cte-de-subcontratacao.tsx" /* webpackChunkName: "component---src-pages-blog-o-que-e-cte-de-subcontratacao-tsx" */),
  "component---src-pages-blog-saiba-como-fazer-um-acerto-de-viagem-tsx": () => import("./../../../src/pages/blog/saiba-como-fazer-um-acerto-de-viagem.tsx" /* webpackChunkName: "component---src-pages-blog-saiba-como-fazer-um-acerto-de-viagem-tsx" */),
  "component---src-pages-blog-sete-dicas-para-controlar-os-pneus-de-sua-frota-tsx": () => import("./../../../src/pages/blog/sete-dicas-para-controlar-os-pneus-de-sua-frota.tsx" /* webpackChunkName: "component---src-pages-blog-sete-dicas-para-controlar-os-pneus-de-sua-frota-tsx" */),
  "component---src-pages-blog-voce-sabe-quais-sao-os-documentos-fiscais-para-o-transporte-de-cargas-tsx": () => import("./../../../src/pages/blog/voce-sabe-quais-sao-os-documentos-fiscais-para-o-transporte-de-cargas.tsx" /* webpackChunkName: "component---src-pages-blog-voce-sabe-quais-sao-os-documentos-fiscais-para-o-transporte-de-cargas-tsx" */),
  "component---src-pages-contato-tsx": () => import("./../../../src/pages/contato.tsx" /* webpackChunkName: "component---src-pages-contato-tsx" */),
  "component---src-pages-empresa-tsx": () => import("./../../../src/pages/empresa.tsx" /* webpackChunkName: "component---src-pages-empresa-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacidade-tsx": () => import("./../../../src/pages/privacidade.tsx" /* webpackChunkName: "component---src-pages-privacidade-tsx" */),
  "component---src-pages-syscon-tsx": () => import("./../../../src/pages/syscon.tsx" /* webpackChunkName: "component---src-pages-syscon-tsx" */),
  "component---src-pages-trabalhe-conosco-tsx": () => import("./../../../src/pages/trabalhe-conosco.tsx" /* webpackChunkName: "component---src-pages-trabalhe-conosco-tsx" */)
}

